<ng-container *transloco="let t; read: 'collection-owner'">
  @if (accountService.currentUser$ | async; as user) {
    <div class="fw-light text-accent">
      {{t('collection-created-label', {owner: collection.owner})}}
      @if(collection.source !== ScrobbleProvider.Kavita) {
        {{t('collection-via-label')}}
        <app-image [imageUrl]="collection.source | providerImage"
                   width="16px" height="16px"
                   [ngbTooltip]="collection.source | providerName"
                   [attr.aria-label]="collection.source | providerName"></app-image>
      }
    </div>
  }
</ng-container>
